// https://css-tricks.com/snippets/jquery/calculate-distance-between-mouse-and-element/
function calculateDistance(elem, mouseX, mouseY) {
  return Math.floor(Math.sqrt(Math.pow(mouseX - (elem.offset().left+(elem.width()/2)), 2) + Math.pow(mouseY - (elem.offset().top+(elem.height()/2)), 2)));
}


function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

function numberWithCommas(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}





jQuery(function($){

  $.protip();

  const cloudPoints = [

    //[15, 10],
    [25, 1],
    //[50, 10],
    [60, 4],
    //[75, 5],
    [80, 15],
    //[75, 30],
    
    [80, 75],
    //[65, 75],
    [55, 80],
    //[45, 75],

    //[25, 75],
    [15, 80],
    //[5, 60],
    

  ];

  let clouds = [];

  $('[data-photo-cloud]').each(function(){

    let cloud = [];
    clouds.push(cloud);

    var $photos = $('[data-photo]', $(this));
    var $parent = $(this);

    $photos.each(function(i){

      if(cloudPoints[i] && cloudPoints[i].length) {

        const point = cloudPoints[i];

        //let x = Math.random() * 150 - 25;
        //let y = Math.random() * 150 - 25;
        let scale = Math.random() * 0.2 + 0.2;

        $(this).data('scale', scale);

        const x = point[0];
        const y = point[1];

        $(this).css({
          left: x + '%',
          top: y + '%',
          display: 'block',
          transform: `scale(${scale})`
        });

        cloud.push($(this));

      }

    });
  });


  $(document).mousemove(function(e) {
    var x = e.pageX;
    var y = e.pageY;
    clouds.forEach((cloud, i) => {
      cloud.forEach((photo, i) => {
        var dist = calculateDistance(photo, x, y);
        const factor = dist / 1000 + 0.5;
        const newScale = photo.data('scale') / factor;
        photo.css({
          transform: `scale(${newScale}`
        });
      })
    });

  }).mouseover(); // call the handler immediately


  // "Load more" functionality
  $('[data-load-more]').each(function(){
    var limit = $(this).data('load-more');
    var $parent = $(this);
    var $items = $('> *', $(this));
    $items.slice(limit).addClass('mobile-hidden');
    const $showMore = $(`<a class="show-more mobile-only">Load more</a>`);
    $showMore.click(function(){
      $(this).remove();
      $items.removeClass('mobile-hidden');
    })
    $parent.append($showMore);
  })



  var levelMap = {
    1: "First major",
    2: "Second major",
    3: "Foundation",
    5: "Undergraduate",
    7: "Postgraduate",
    17: "Postgraduate research",
    18: "Postgraduate research",
    19: "Postgraduate research",
    20: "Certificates of less than 12 weeks",
    21: "Certificates of at least 12 weeks but less than 1 year",
    2: "Certificates of at least 1 but less than 2 years",
    4: "Certificates of at least 2 but less than 4 years",
    6: "Postbaccalaureate",
    8: "Post-master&apos;s", 
  }


  // Pull in the course data from the pre-generated JSON
  $('[data-course-table]').each(function(){
    var id = $(this).data('course-table');

    var json = "/feed/inst/"+id+"/courses.json";

    var $container = $(this);

    var $buttons = $('[data-course-table-buttons');
    var $select = $('[data-academics-select]');

    $.getJSON(json, function(data) {

      if(data[17] === undefined) {
        data[17] = [];
      }

      for(var level in data) {
        level = parseInt(level);
        if(level == 18 || level == 19) {
          // Put all of the other postgrad etc. courses into 17
          data[17].concat[data[level]];
        }
      }
      
      for(var level in data) {

        level = parseInt(level);

        if(![3,5,7,17].includes(level) || data[level].length == 0) {
          continue;
        }


        var levelTitle = levelMap[level];
        var levelId = level;

        var $button = $(`<div class="level__panel">
                            <div class="level__icon">
                                <img src='/img/universities/cubes.png' alt='Cubes' />
                            </div>
                            <h3 class="level__title">${levelTitle}</h3>
                            <a data-tab-anchor='${levelId}' href="#" class="button --small-corner">Find Programmes</a>
                        </div>`);

        var $option = $(`<option value='${levelId}'>${levelTitle}</option>`);
        $select.append($option);

        $buttons.append($button);

        var $table = $(`<table class="detail-table" data-tab="${levelId}"></table>`);

        

        var $head = $(`<thead>
                            <tr>
                                <th>Programme Title</th>
                                <th>Start date</th>                                
                            </tr>
                        </thead>`);

        $table.append($head);

        var $body = $('<tbody></tbody>');

        var courses = data[level];
        courses = courses.sort(function(a, b){
            if(a.course < b.course) { return -1; }
            if(a.course > b.course) { return 1; }
            return 0;
        })

        for(var course in courses) {
          var name = courses[course]['course'];
          name = name.replace(", Other", "");
          name = name.replace(", General", "");
          name = name.trim();
          if (name[name.length-1] === ".") {
            name = name.slice(0,-1);
          }
          var major = courses[course]['major'];
          if(major == 1) {
            var $row = $(`<tr>
                              <td>${name}</td>
                              <td>2022</td>
                          </tr>`);
            $body.append($row);
          }
        }

        $table.append($body);

        $container.append($table);

        $table.hide(0);
      
      }


        var $close = $(`<a data-close-academics>Close</a>`);

      var contents = $container.contents();
      $container.replaceWith(contents);
    });
  })





  // Range slider
  var r = $('input[type="range"]').each(function(){

    var $bubble = $('<div class="rangeslider__bubble"></div>');
    $(this).after($bubble);

    var $wrapper = $(this).parent('.wrapper');
    

    var append = $(this).data('append-label');

    if(!append) {
      append = '';
    }

    var min = $(this).prop('min');
    var max = $(this).prop('max');
    var val = $(this).val();
    var total = max - min;

    function handleSlide(position, value) {
      var $rangeslider = $('.rangeslider', $wrapper);
      var w = $rangeslider.width();

      var dist = ((value - min) / total) * w; 
      $bubble.css({
        left: (dist - ($bubble.width() / 2)) + 'px'
        //left: ((value - min) / total * 100) + '%'
      });
      if(value <= min) {
        $bubble.html('Any');
      } else {
        $bubble.html(value + '' + append);  
      }
      
    }

    

    var dist = (val - min) / total;

    var width = $(this).width();

    var diff = max - min;
    var current = val - min;
    var pc = (current/diff) * $(this).width();
    
    $rangeslider = $(this).rangeslider({
      polyfill: false,
      onSlide: handleSlide
    });

    handleSlide(pc + 5, $(this).val());

    $(this).triggerHandler('onSlide');

  });




  // Sidebar toggle option
  $('[data-mobile-toggle]').each(function(){
    
    var $this = $(this);
    var id = $(this).data('mobile-toggle');
    var $link = $('[data-mobile-toggle-target="' + id + '"]');

    if($link.length) {
      $link.click(function(){
        
        $this.toggleClass('visible');

        if($this.is(':visible')) {
          $('#popup-bg').removeClass('hidden');
        } else {
          $('#popup-bg').addClass('hidden');
        }
      });
    }

    //$(this).hide(0);

  });


  $('[data-set-sort]').on('change', function(){
    if($(this).val()) {
      $(this).parents('form').submit();
    }
  });

  /** Archive search functionality **/
  $('form[data-submit-on-change] select').on('change', function(){
    $(this).parents('form').submit();
  });

  $('[name="year"]').each(function(){

    $(this).on('change', function(){
      if(!$(this).val()) {
        $('[name="month"]').attr('disabled', true);
        $('[name="month"]').val('');
      } else {
        $('[name="month"]').attr('disabled', false);
        $('[name="month"]').val('');
      }
    });

    

  });

  if($('[name="year"]').val() == "") {
    $('[name="month"]').attr('disabled', true);
    //$('[name="month"]').val('');
  } else {
    $('[name="month"]').attr('disabled', false);
    //$('[name="month"]').val('');
  }

  /*$('.blog__index-header-controls a').on('click', function(e){
    e.preventDefault();
    return true;
  })*/

  $('[data-newsroom-type]').on('change', function(){
    let value = $(this).val();
    $('[data-view]').addClass('blog__toggle-hidden');
    $(`[data-view='${value}']`).removeClass('blog__toggle-hidden');
    return true;
  })


  var $academicsSelect = $('[data-academics-select]');

  
  $(document).on('click', '[data-tab-anchor]', function(){
    var id = $(this).data('tab-anchor');
    var $target = $(`[data-tab='${id}']`);
    var $siblings = $target.siblings();

    
    $siblings.fadeOut(0);
    $target.fadeIn(500);
    $(this).siblings().removeClass('active');
    $(this).addClass('active');

    //special case for academics section
    
    if($academicsSelect.length) {
      $academicsSelect.val(id);      
    }

    return false;


  });

  $(document).on('click', '.level__panel a', function(e){
    $('[data-academics-title]').hide(0);
    $('[data-academics-title-alt]').fadeIn(500);
    e.preventDefault();
  })

  $(document).on('click', '[data-close-academics]', function(e){
    $('[data-academics-title]').fadeIn(500);
    $('[data-academics-title-alt]').hide(0);
    $('[data-tab-anchor]:first-child').click();
    window.location.hash = "academics";
    e.preventDefault();
  })

  $academicsSelect.on('change', function(){
    var value = $(this).val();
    $('[data-tab-anchor='+value+']').click();
  });


  
  $('[data-tab]').not(':first').hide(0, function(){
    $('[data-tab-anchor]:first-child').click();
  });
  


  $('[data-detacher] ul').each(function(){
    var $target = $(this);
    var $container = $(this).parent();

    /*$(window).on('resize', function(){
      targetTop = $target.offset().top;
    });*/

    $(window).on('scroll', function(){
      
      var top = $(window).scrollTop();
      var elTop = $container.offset().top;
      if(top > elTop) {
        $target.addClass('detached');
      } else {
        $target.removeClass('detached');
      }
    });
  });

  $('[data-currency-target]').each(function(){
    var str = $(this).html();
    var resUSD = str.match(/\$[0-9\,].*/g);
    var resGBP = str.match(/\£[0-9\,].*/g);
    var resEUR = str.match(/\€[0-9\,].*/g);
    var sym = "";
    if(resUSD) {
      var value = resUSD[0].replace(/\D/g,'');
      $(this).attr('data-value', value);
      $(this).attr('data-currency', 'USD');
      $(this).attr('data-string', str.replace(resUSD[0], 'AMOUNT'));
      var sym = "$";
    } else if(resGBP) {
      var value = resGBP[0].replace(/\D/g,'');
      $(this).attr('data-value', value);
      $(this).attr('data-currency', 'GBP');
      $(this).attr('data-string', str.replace(resGBP[0], 'AMOUNT'));
      var sym = "£";
    } else if(resEUR) {
      var value = resEUR[0].replace(/\D/g,'');
      $(this).attr('data-value', value);
      $(this).attr('data-currency', 'EUR');
      $(this).attr('data-string', str.replace(resEUR[0], 'AMOUNT'));
      var sym = "€";
    } else {
      $(this).attr('no-data', 1);
    }

    if(value) {
      $(this).html(sym + numberWithCommas(value));
    }
    
  });

  $('.at-a-glance__tab').each(function(){
    // number of processed currencies
    var count = $('[data-currency-target][data-currency]', $(this)).length;
    if(!count) {
      $('[data-currency-convert]', $(this)).remove();
    }
  });

  /*var exchanges = {
    CNY: 157,
    usd: 1.38,
    gbp: 1
  };*/

  var exchanges = false;
  $.get('/exchangerates/index.php', function(data, status){
    exchanges = data;
  });

  var $exchangeTargets = $('[data-currency-target]');

  const converter = (val, currency = 'USD', base = 'USD') => {
    let rate = exchanges[base]
    let baseRatio = 1 / exchanges[base] 
    rate = exchanges[currency] * baseRatio
    console.log(rate)
    return Math.round(rate * val)
  }

  $('[data-currency-convert] button').on('click', function(){
    var currency = $(this).data('currency');
    var base = $(this).data('base')

    $('[data-currency-convert] button').removeClass('active');
    $('[data-currency-convert] button[data-currency='+currency+']').addClass('active');

    if(!exchanges) {
      return false;
    }

    $exchangeTargets.each(function(){

      var str = $(this).data('string');
      if(currency == 'GBP') {
        var symbol = "£";
      }
      if(currency == 'EUR') {
        var symbol = "€";
      }
      if(currency == 'CNY') {
        var symbol = "¥";
      }
      if(currency == 'USD') {
        var symbol = "$";
      }
      var exchanged = converter($(this).data('value'), currency, base)
      exchanged = exchanged.toLocaleString("en-US");
      if(exchanged && str) {
        $(this).html(str.replace("AMOUNT", symbol+""+exchanged));
      }
    });
  });


  $('.university__about').each(function(){
    var h = $(this).height();
    var $this = $(this);
    if(h > 640) {
      $(this).addClass('readmore');
      var $link = $('<a href="#" style="margin: 0 auto;" class="button read-more-button">Read more</a>');
      $link.on('click', function(){
        $this.removeClass('readmore');
        $(this).remove();
        return false;
      })
      $(this).after($link);
    }
  });


  


  $('.init').on('click', initKeyCloak);


  /*setTimeout(function(){
    if(typeof map !== 'undefined') {
      map.scrollZoom.disable();
    }
  }, 500);*/




  /**
   * FAQ Section
   */
  

  // 1. Organise FAQs into categories
  const categories = {}
  $('[data-faq-items] [data-faq-item]').each(function() {
    const category = $(this).data('category')
    if(categories[category] == undefined) {
      categories[category] = []
    }
    categories[category].push($(this))
    $(this).detach()
  })

  const $faqsContainer = $('<div>')
  Object.keys(categories).forEach(category => {
    $items = categories[category]
    const $categoryContainer = $(`<div><h2 data-category-title>${category}</h2></div>`)
    //console.log(category)
    $items.forEach($item => {
      $categoryContainer.append($item)
      $item.show(0)
    })
    $faqsContainer.append($categoryContainer)
  })
  $('[data-faq-items]').append($faqsContainer)

  $noresult = $('<h2 class="faq__no-result">No results</h2>');
  $faqsContainer.append($noresult)
  $noresult.hide()

  $('[data-faq-items] [data-faq-item]').on('click', function() {
    $(this).toggleClass('active')
    $('[data-faq-item]').not(this).removeClass('active')
  })

  jQuery.expr[':'].icontains = function(a, i, m) {
    return jQuery(a).text().toUpperCase()
        .indexOf(m[3].toUpperCase()) >= 0;
  };

  $('[data-faq-search]').on('keyup', function(){
    const val = $(this).val()
    if(val) {
      $('[data-category-title]').hide()
      $('[data-faq-item]').hide()
      $(`[data-faq-item]:icontains('${val}')`).show()
      if($('[data-faq-item]:visible').length) {
        $noresult.hide()
      } else {
        $noresult.show()
      }
    } else {
      $noresult.hide()
      $('[data-faq-item]').show()
      $('[data-category-title]').show()
    }
  })
  

  /**
   * Our story section
   */
  $('[data-bio-popup]').each(function(){
    const bio = $(this).data('bio')
    const title = $(this).data('title')
    const name = $(this).data('name')
    const position = $(this).data('position')
    const image = $(this).data('image')
    $(this).click(_ => {
      showModal(bio, name, position, image)
    })
  })


  /**
   * Generic modal functionality
   */
  const $modalContainer = $('<div class="modal-container">');
  const $modal = $('<div data-modal class="modal">');
  
  const $modalText = $('<div data-text class="modal__text">');
  const $modalTitle = $('<h3 data-title class="modal__title"></h3>');
  const $modalSubtitle = $('<h4 data-subtitle class="modal__subtitle"></h4>');
  const $modalImageContainer = $('<div class="modal__image">');
  const $modalImage = $('<img data-image src="" />')
  $modalImageContainer.append($modalImage)

  $modalContent = $('<div class="modal__content">')

  $modalContent.append($modalTitle)
  $modalContent.append($modalSubtitle)
  $modalContent.append($modalText)
  $modal.append($modalContent)
  $modal.append($modalImageContainer)

  $("body").append($modalContainer);
  $modalContainer.append($modal);
  $modalContainer.hide();

  $modalClose = $('<div class="modal__close">');
  $modal.append($modalClose)
  $modalClose.click(function(){
    $modalContainer.fadeOut(250)
  })

  window.showModal = (text, title, subtitle, image = '') => {
    const $modal = $('[data-modal]')
    $('[data-title]', $modal).html(title)
    $('[data-subtitle]', $modal).html(subtitle)
    $('[data-text]', $modal).html(text)
    $('[data-image]', $modal).attr('src', image)
    $modalContainer.fadeIn(250)
  }




  // Header subnav hover state for mobile
  $('.site-header__nav a:not([href])').click(function(){
    $(this).toggleClass('hover')
  })


});


