
/**
 * Keycloak auth functionality
 */

const keycloakConfig = {
  realm: "inspirus",
  //url: "http://inspi-loadb-14kkny4blr5x5-543824317.eu-west-1.elb.amazonaws.com/auth/",
  //tokenEndpoint: "http://inspi-loadb-14kkny4blr5x5-543824317.eu-west-1.elb.amazonaws.com/auth/realms/inspirus/protocol/openid-connect/token",
  url: "https://auth.inspirus.io/auth/",
  tokenEndpoint: "https://auth.inspirus.io/auth/realms/inspirus/protocol/openid-connect/token",
  "ssl-required": "external",
  "public-client": true,
  "confidential-port": 0,
  clientId: "inspirus",
  onLoad: "check-sso",
  loginRedirectUri: "",
  //authServerUrl: 
  registerRedirectUri: "",
  redirectUri: "",
  
};

let keycloakData = {};

let apiEndpoint = "https://api.inspirus.io/"

let currentInspirusUser = null
let currentInspirusUserShortlist = null

let localShortlist = []
// array of uni objects stored within shortlist as presented by API
let localShortlistLoaded = false

let uniList = []
// Local data pulled in from /university endpoint

const uidMap = {}
// A map of local university institution IDs to university objects
  


let startupPromise

function kcStartup() {
  const p = new Promise(resolve => {
    const result = initKeyCloak()
    //console.log(result)
    result.then(_ => {
      renderPageElements()
      resolve()
    })
  })

  $(document).on('click', '[data-remove-fave]', function(e){
    const uid = $(this).data('remove-fave')
    removeFromShortlist(uid)
    e.preventDefault()
    return false
  })

  return p
}

$(function(){
  kcStartup()
})

let keycloakInit
let accessToken

async function initKeyCloak() {
  keycloakInit = await KeycloakFactory.init();
  if (keycloakInit.authenticated) {
    const userInfo = await keycloakInit.loadUserInfo();
    setKeycloakData({
      username: userInfo.email,
      id: userInfo.sub,
      authenticated: true,
      keycloak: keycloakInit,
    });

    // For testing...
    kcStorage.removeItem('university')

    let unisListData = kcStorage.getItem('university')
    const now = new Date

    if (unisListData && now.getTime() > unisListData.expiry) {
      kcStorage.removeItem('university')
      unisListData = null
    }

    if(!unisListData) {
      await getUniversityData().then(data => {
        unisListData = {
          item: data,
          //expires: now.getTime() + 1000 * 60 * 60 * 24, // expire after a day
          expires: now.getTime() + 1000 * 60, // expire after a minute for testing
        }
        kcStorage.setItem('university', unisListData)
      })
    }

    uniList = unisListData.item

    uniList.forEach(uni => {
      uidMap[uni.institutionId] = uni.uid
    })


    return new Promise(resolve => {

      getUserData().then(data => {
        if(data.id) {
          console.log(data)
          currentInspirusUser = data
          getShortlistData().then(data => {
            console.log("Shortlist data", data)
            //console.log(uniList)
            data.forEach(uid => {
              localShortlist.push(uid)
            })
            localShortlistLoaded = true
            resolve()
            //console.log("Shortlist retrieved")
            //return Promise.resolve()          
            // allow user to begin interaction here
          })
        }
      })
    })
    
  } else {
    //keycloakInit.login({ redirectUri: 'https://app.inspirus.io'});
  }
}

let bearerToken, refreshToken

function setKeycloakData(data) {
  //console.log("Setting keycloak data", data.keycloak.token)
  keycloakData = data;
  bearerToken = data.keycloak.token
  refreshToken = data.keycloak.refreshToken
  console.log(data)
}

const kcStorage = window.localStorage

var callInspirusApi = async function(endpoint, method = 'GET', data = []) {
  var url = apiEndpoint + endpoint

  const fetchOptions = {
    method,
    //mode: 'cors',
    //cache: 'no-cache',
    //credentials: 'same-origin',
    headers: {
      //'Accept': 'application/json',    
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + bearerToken,    
      //'Access-Control-Allow-Origin': window.location.origin + "/",    
    }
  }

  if(['POST', 'PUT', 'PATCH'].includes(method)) {
    fetchOptions.body = JSON.stringify(data)
  }
  
  await startupPromise
  
  try {
    const token = await KeycloakFactory.refreshTokens()
    if(token)
      bearerToken = token.accessToken
    const response = await fetch(url, fetchOptions)
    return response
  } catch(e) {
    return Promise.resolve()
  }
}

var getPerson = async function () {
    const response = await callInspirusApi('person')
    return response.json()
};

var getUserData = async function () {
    const response = await callInspirusApi('person/me')
    return response.json()
};

var getUniversityData = async function() {
  const response = await callInspirusApi('university')
  return response.json()
}

// Only call this if the user has an account
var getShortlistData = async function () {
  if(currentInspirusUser) {
    const response = await callInspirusApi('person/' + currentInspirusUser.id + '/shortlist')
    console.log(response)
    return response.json()
  } else {
    return Promise.resolve()
  }
};

var postShortlistData = async function () {
  if(currentInspirusUser) {
    const response = await callInspirusApi('person/' + currentInspirusUser.id + '/shortlist', 'PUT', localShortlist)
    return response.json()
  } else {
    return Promise.resolve()
  }
}


var addToShortlist = function(id) {
  if(uidMap[id] != undefined) {
    const uid = uidMap[id]
    if(!localShortlist.includes(uid)) {
      localShortlist.push(uid)
    }
    return new Promise(resolve => {
      postShortlistData().then(_ => {
        renderPageElements()
        resolve()
      })
    })
  } else {
    throw new Error("Cannot find that uni id " + id)
  }
}


var removeFromShortlist = function(id) {
  if(uidMap[id] != undefined) {
    const uid = uidMap[id]
    localShortlist = localShortlist.filter(item => item !== uid)
    return new Promise(resolve => {
      postShortlistData().then(_ => {
        renderPageElements()
        resolve()
      })
    })
  } else {
    throw new Error("Cannot find that uni id " + id) 
  }

}

function getShortlist()
{

}

/**
 * End keycloak Auth functionality
 */

/*function testAdd() {
  addToShortlist(191241)
  addToShortlist(213543)
  addToShortlist(190099)
  renderPageElements()
}*/

$('[data-login-button]').css({
  opacity: 0,
})

function renderPageElements()
{

  //console.log("Rendering page elements")


  /**
   * Render a single "Add to favourites" button
   */
  
  $('[data-fave-button]').each(function(){

    $(this).html('')

    if(currentInspirusUser) {

      

      const id = $(this).data('fave-button')
      const uid = uidMap[id]

      if(!uid) {
        //$(this).html('(missing id)')
        return
      }

      const $button = $('<a class="fave-icon">')

      //$button.attr('data-pt-position', 'top')
      //$button.attr('data-pt-title', 'Favourite')
      

      if(localShortlist.includes(uid)) {
        //$button.attr('data-pt-title', 'Remove favourite')
        $button.addClass('active')
        $button.on('click', e => {
          removeFromShortlist(id)
          e.preventDefault()
          return false
        })
      } else {
        //$button.attr('data-pt-title', 'Add favourite')
        $button.removeClass('active')
        $button.on('click', e => {
          addToShortlist(id)
          e.preventDefault()
          return false          
        })
      }

      $(this).append($button)
    } else {
      const $button = $('<a class="fave-icon no-session">')

      $button.on('click', e => {
        const text = `
        <p>Use your Inspirus account to shortlist universities.</p>
        <p><a style='cursor:pointer' onclick="keycloakInit.login({ redirectUri: 'https://app.inspirus.io'})">Login or register</a></p>
        `
        showModal(text, "Your Inspirus Account", "", "/img/our-story/header.png")
      })

      $(this).append($button)
    }
  })


  

  if(currentInspirusUser) {
    $('.primary-login').hide(0)
    $('[data-login-name]').html(currentInspirusUser.givenName)
    if(currentInspirusUser.avatar) {
      $('[data-login-image]').attr('src', currentInspirusUser.avatar)
    } else {
      $('[data-login-image]').attr('src', '/img/blank-avatar.svg')  
    }
    
    $('[data-profile-dropdown]').addClass('active')
  } else {
    $('[data-profile-dropdown]').removeClass('active')
    $('.primary-login').show(0)
  }


  $('[data-login-button]').each(function() {
    $(this).css({ opacity: 1 })
    $(this).html('')
    const loginText = $(this).data('login-text') || 'Login'
    const logoutText = $(this).data('logout-text') || 'Logout'
    const hideLogout = $(this).data('hide-logout') || false
    
    if(currentInspirusUser) {
      if(!hideLogout) {
        const $button = $(`<a href="#">${logoutText}</a>`)
        $button.on('click', e => {
          console.log("Logout action")
          KeycloakFactory.logout().then(_ => {
            console.log("Logged out")
          })
          e.preventDefault()
          return false
        })
        $(this).append($button)
      }
    } else {
      const $button = $(`<a href="#">${loginText}</a>`)
      $button.on('click', _ => {
        $button.html("Wait...")
        keycloakInit.login({ redirectUri: 'https://app.inspirus.io'}).then(_ => {
          $button.html("Logout")
        })
      })
      $(this).append($button)

    }
  }) 



  /**
   * Render the list of sidebar favourites
   * @type {[type]}
   */
  const $favouritesContainer = $('[data-uni-faves]')
  if(!$favouritesContainer.length)
    return

  $favouritesContainer.html('')

  let listFinished = false

  localShortlist.forEach((uid, i) => {

    if(i >= 3) {
      if(listFinished == false && localShortlist.length > i) {
        const more = localShortlist.length - i
        $favouritesContainer.append(`<p><a href='https://app.inspirus.io'>...and ${more} more</a></p>`)
      }
      listFinished = true
      return
    }

    const uni = uniList.find(uni => uni.uid == uid)

    if(!uni) {
      return
    }

    let photo

    if(uni.universityPhoto != undefined && uni.universityPhoto.length > 0) {
      photo = uni.universityPhoto[0].url
    } else {
      // todo: placeholder
    }

    photo = unithumbs[uni.institutionId]

    const row = `<a class="faves__fave" href="${uni.url}">
      <img src="${photo}" class="faves__img" />
      <div class="faves__content">
        <h4 class='faves__title'>${uni.institutionName}</h4>
        <p class='faves__location'>Santa Clara, California</p>
        <span href='#' class='faves__close' data-remove-fave="${uni.institutionId}">Close</span>
      </div>
    </a>`

    const $row = $(row)

    $favouritesContainer.append($row)

  })

  if($favouritesContainer.html() == '') {
    $favouritesContainer.html("No favourites")
  }

  
}



const KeycloakFactory = (function () {
  let instance;
  let tokens;

  async function refreshTokens() {
    const data = new URLSearchParams();
    data.append("client_id", "inspirus");
    data.append("grant_type", "refresh_token");
    data.append("refresh_token", refreshToken);
    //console.log("Refreshing");
    try {
      const resp = await fetch(keycloakConfig.tokenEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: data,
      });

      const respJson = await resp.json();

      if (respJson.error) throw respJson;

      tokens = { accessToken: respJson.access_token, refreshToken: respJson.refresh_token };
      return tokens;
    } catch (error) {
      if (error.error === "invalid_grant") {
        // refresh token has expired, redirect to login page with appended redirect uri to return to same page
        console.log(error);
        throw error
        /*const loginRedirectUrl =
          `http://inspi-loadb-14kkny4blr5x5-543824317.eu-west-1.elb.amazonaws.com/auth/` +
          `realms/inspirus` +
          `/protocol/openid-connect/auth?client_id=${keycloakConfig.clientId}` +
          `&redirect_uri=${encodeURIComponent(window.location.href)}`;

        window.location.href = loginRedirectUrl;*/
      }
    }
  }

  function createInstanceFromToken(accessToken, idToken, refreshToken) {
    const keycloak = Keycloak(keycloakConfig);

    //console.log(accessToken, idToken, refreshToken)

    return keycloak
      .init({
        token: accessToken,
        idToken: idToken,
        refreshToken: refreshToken,
        //silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
      })
      .then(() => {
        tokens = {
          accessToken: keycloak.token,
          refreshToken: keycloak.refreshToken,
        };

        return keycloak;
      })
      .catch((e) => {
        throw new Error("unable to initialize authentication flow");
      });
  }

  function createInstance() {
    const keycloak = Keycloak(keycloakConfig);
    //console.log("Creating Instance");
    return keycloak
      .init({
        onLoad: keycloakConfig.onLoad,
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
      })
      .then(() => {
        //console.log("After init");
        keycloak.onTokenExpired = () => {
          keycloak.updateToken(30).catch((e) => {
            console.log("Error, unable to refresh token");
            throw new Error("Unable to refresh token");
          });
        };

        tokens = {
          accessToken: keycloak.token,
          refreshToken: keycloak.refreshToken,
        };


        

        return keycloak;
      })
      .catch((e) => {
        throw new Error("unable to initialize authentication flow");
      });
  }

  return {
    initFromTokens: function (accessToken, idToken, refreshToken) {
      if (!instance) {
        instance = createInstanceFromToken(accessToken, idToken, refreshToken);
      }
      return instance;
    },
    init: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
    getUserInfo: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance.then((keycloak) => keycloak.loadUserInfo().then((info) => info));
    },
    logout: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance.then((keycloak) => keycloak.logout());
    },
    getTokens: function () {
      if (!instance) {
        instance = createInstance();
      }
      return tokens;
    },
    refreshTokens: async function () {
      if (!instance) {
        instance = createInstance();
      }
      await refreshTokens();
      return tokens;
    },
    handleRefreshTokens: async function () {
      if (instance) {
        instance = refreshTokens();
      }
      return tokens;
    },
    getRefreshToken: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance.then((keycloak) => keycloak.refreshToken);
    },
  };
})();

