$('body').bind('keypress', function(e) {
	// TODO: Need to have a nice close solution
	var code = e.keyCode || e.which;
	if(code == 27) {
		$('.instagram-stack__photo').removeClass('active');
	}
});
$(function(){
	$('.instagram-stack__photo').click(function(e){
		$('.instagram-stack__photo').removeClass('active');
		$(this).addClass('active');
		e.preventDefault();
	});
	$('.js-page-tabs a').click(function(e){
		let goToTab = $(this).attr('href');
		$('.js-page-tabs a').removeClass('active');
		$(this).addClass('active');
		$('.js-content').removeClass('active');
		$('.js-content'+goToTab).addClass('active');
		e.preventDefault();
	});
	$('.student-experiences .tabs a').click(function(e){
		let activeContent = $(this).attr('href');
		$('.student-experiences .tabs a').removeClass('active');
		$(this).addClass('active');
		$('.student-experiences .student-experiences__reel-content').removeClass('active');
		$('.student-experiences .student-experiences__reel-content'+activeContent).addClass('active');
		e.preventDefault();
	});

	$('.student-experiences .tabs li').each(function(){
		var id = $(this).data('tab');
		var $tab = $('#' + id);
		if($('ul li', $tab).length == 0) {
			$('[data-tab="'+id+'"]').hide(0);
		}
	});
	$('.student-experiences .tabs li:visible a').first().click();

	$('.student-experiences-pg .tabs a').click(function(e){
		let activeContent = $(this).attr('href');
		$('.student-experiences-pg .tabs a').removeClass('active');
		$(this).addClass('active');
		$('.student-experiences-pg .student-experiences__reel-content').removeClass('active');
		$('.student-experiences-pg .student-experiences__reel-content'+activeContent).addClass('active');
		e.preventDefault();
	});
});